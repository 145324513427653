<!--
* @description eicad管理平台
* @fileName eicad.vue
* @author liulian
* @date 2023/06/29 16:33:22
-->
<template>
  <div class="eicad">
    <div class="z_banner">
      <img class="z_b_img" :src="$t(`eicad.banner`)" alt="banner" />
    </div>
    <div class="ptjs">
      <div class="p_con">
        <div class="z_title text-align-center font-size-36 font-weight-600">{{ $t("eicad.section1_title") }}</div>
        <div class="p_descript font-size-18 font-weight-400">
          {{ $t("eicad.section1_content1") }}<br />{{ $t("eicad.section1_content2") }}
        </div>
        <div class="mode_box">
          <img class="mode_bg" :src="$t(`eicad.section1_img`)" alt="模块图" />
        </div>

        <div class="z_title text-align-center font-size-36 font-weight-600">{{ $t("eicad.section2_title") }}</div>
        <div class="p_hxnl flex-row flex-wrap">
          <div class="p_h_item flex-row-center align-center" :class="index % 2 === 0 ? 'p_h_item_odd' : 'p_h_item_simple'" v-for="(item, index) in rjtss" :key="index">
            <div class="p_h_i_r_name font-size-18 font-weight-400 text-align-center">{{ $t(`eicad.${item.name}`) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="eicad_box">
      <img class="e_img" :src="$t(`eicad.section2_img`)" alt="eicad_img@2x.png" />
    </div>
    <div class="z_title text-align-center font-size-36 font-weight-600">{{ $t("eicad.section3_title") }}</div>
    <div class="htljsj">
      <div class="h_description font-size-18 font-weight-400">
        <p>{{ $t("eicad.section3_content1") }}</p>
        <p>{{ $t("eicad.section3_content2") }}</p>
      </div>
      <div class="h_imgs flex-row-center align-center">
        <img class="h_i_img" src="@/assets/images/productImg/htsj_1@2x.png" alt="htsj_1@2x.png" />
        <img class="h_i_img" src="@/assets/images/productImg/htsj_2@2x.png" alt="htsj_2@2x.png" />
      </div>
    </div>
    <div class="z_title text-align-center font-size-36 font-weight-600">{{ $t("eicad.section4_title") }}</div>
    <div class="bplj">
      <div class="b_p font-size-18 font-weight-400">{{ $t("eicad.section4_content1") }}</div>
      <div class="b_imgs flex-row-center align-center">
        <img class="b_i_img" src="@/assets/images/productImg/lpnsj_1@2x.png" alt="lpnsj_1@2x.png" />
        <img class="b_i_img" src="@/assets/images/productImg/lpnsj_2@2x.png" alt="lpnsj_2@2x.png" />
      </div>
    </div>
    <!-- 平台亮点 -->
    <div class="p_ptld">
      <div class="z_title text-align-center font-size-36 font-weight-600">{{ $t("eicad.section5_title") }}</div>
      <div class="p_p_con flex-row-between flex-wrap">
        <div class="p_p_c_item" v-for="(item, index) in gnmk" :key="index">
          <div class="p_h_i_right">
            <img class="p_p_c_item_img" :src="$t(`eicad.${item.default}`)" alt="封面图" />
          </div>
            <!-- 反转后背景图 -->
            <div class="p_p_c_item_hover">
            <img class="p_p_c_item_hover_img" :src="$t(`eicad.${item.cover}`)" alt="封面图" />
            <div class="p_hover_title font-size-24 font-weight-600 font-color-white-ffffff">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "eicad",
  components: {},
  data() {
    return {
      rjtss: [
        {
          name: "section2_item1",
        },
        {
          name: "section2_item2",
        },
        {
          name: "section2_item3",
        },
        {
          name: "section2_item4",
        },
        {
          name: "section2_item5",
        },
        {
          name: "section2_item6",
        },
        {
          name: "section2_item7",
        },
        {
          name: "section2_item8",
        },
      ],
      gnmk: [
        {
          default: "section5_img_default1",
          cover: "section5_img_cover1",
        },
        {
          default: "section5_img_default2",
          cover: "section5_img_cover2",
        },
        {
          default: "section5_img_default3",
          cover: "section5_img_cover3",
        },
        {
          default: "section5_img_default4",
          cover: "section5_img_cover4",
        },
        {
          default: "section5_img_default5",
          cover: "section5_img_cover5",
        },
        {
          default: "section5_img_default6",
          cover: "section5_img_cover6",
        },
        {
          default: "section5_img_default7",
          cover: "section5_img_cover7",
        },
        {
          default: "section5_img_default8",
          cover: "section5_img_cover8",
        },
        {
          default: "section5_img_default9",
          cover: "section5_img_cover9",
        },
      ],
      // 典型工程
      dxgcs: [
        {
          cover: require("../../../assets/images/productImg/dxgc_1@2x.png"),
        },
        {
          cover: require("../../../assets/images/productImg/dxgc_2@2x.png"),
        },
        {
          cover: require("../../../assets/images/productImg/dxgc_3@2x.png"),
        },
      ],
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.eicad {
  padding-bottom: 171px;
  background-color: #fff;
  .z_title {
    color: rgba(29, 29, 31, 0.9);
  }
  .z_b_img {
    width: 100%;
    height: 400px;
  }
  .ptjs {
    // height: 1010px;
    margin-top: -3px;
    background-color: #fff;

    .p_con {
      width: 1200px;
      padding-top: 64px;
      margin: auto;

      .p_descript {
        margin-top: 18px;
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 1px;
        margin-bottom: 76px;
        text-indent: 36px;
        color: rgba(29, 29, 31, 0.8);
      }
      .mode_box {
        text-align: center;
        margin-bottom: 80px;
      }
      .mode_bg {
        width: 1120px;
        height: 293px;
      }
      .p_dxgc {
        margin-top: 32px;
        margin-bottom: 64px;
        .p_d_item {
          width: 380px;
          height: 214px;
          .p_d_i_img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .p_hxnl {
        margin-top: 32px;
        // padding-bottom: 64px;

        .p_h_item {
          width: 240px;
          height: 140px;
          background: #fdfcf7;
          .p_h_i_r_name {
            color: #3a260d;
          }
        }
        .p_h_item_simple {
          background: #fefaf2;
        }
        .p_h_item_odd {
          background: #fdfcf7;
        }
      }
    }
  }
  .eicad_box {
    width: 100%;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 80px;
    .e_img {
      width: 1200px;
      height: 560px;
    }
  }
  .htljsj {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 50px;
    .h_description {
      width: 1200px;
      color: rgba(29, 29, 31, 0.6);
      margin: auto;
      line-height: 32px;
      letter-spacing: 1px;
      p {
        text-indent: 36px;
      }
    }
    .h_imgs {
      margin: 32px auto;
      width: 1200px;
      .h_i_img {
        margin-left: 16px;
        margin-right: 16px;
      }
      .h_i_img:first-child {
        width: 760px;
        height: 460px;
      }
      .h_i_img:last-child {
        width: 448px;
        height: 460px;
      }
    }
  }
  .bplj {
    margin: 32px auto;
    width: 1200px;
    .b_p {
      color: rgba(29, 29, 31, 0.6);
      line-height: 32px;
      letter-spacing: 1px;
      text-indent: 36px;
      width: 1200px;
    }
    .b_imgs {
      margin-top: 32px;
      .b_i_img {
        margin-left: 16px;
        margin-right: 16px;
        height: 380px;
      }
      .b_i_img:first-child {
        width: 720px;
      }
      .b_i_img:last-child {
        width: 448px;
      }
    }
  }
  .p_ptld {
    padding-top: 40px;
    background: #f8f8f8;
    padding-bottom: 120px;
    .p_p_con {
      width: 1200px;
      margin: 32px auto;  
      padding-bottom: 34px;
      

      .p_p_c_item {
        width: 380px;
        height: 300px;
        // background-image: url("../../../assets/images/productImg/gnmk_bg@2x.png");
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        margin-top: 30px;
        position: relative;
        .p_h_i_right {
          // margin-top: 30px;
          color: rgba(29, 29, 31, 0.6);
          .p_c_name {
            width: 300px;
            margin-left: 44px;
          }
        
          .p_p{
            position: relative;
            width: 300px;
            line-height: 24px;
            margin-bottom: 16px;
            text-align: justify;
            margin-left: 44px;
          }
          .p_p:first-child{
            margin-top: 17px;
          }
          .p_p::before{
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            left: -20px;
            top:6px;
            border-left: 12px solid #FF9413 ;
            border-right: 12px solid transparent;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
          }
        }
        .p_p_c_item_hover {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: none;
          .p_p_c_item_img {
            width: 100%;
            height: 100%;
          }
          .p_p_c_item_hover_img {
            width: 100%;
            height: 100%;
          }
          .p_hover_title {
            width: 100%;
            height: 64px;
            position: absolute;
            line-height: 64px;
            left: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.13) 15%, rgba(0, 0, 0, 0.8) 100%);
            padding-left: 18px;
            padding-right: 18px;
          }
        }
      }
      .p_p_c_item:hover {
        .p_p_c_item_hover {
          display: block;
          -webkit-animation: fadeIn 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: fadeIn 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        }

        @-webkit-keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
